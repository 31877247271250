a {
  transition:all 0.3s ease;
}
.btn {
  font-weight:bold;
  text-transform:uppercase;
}

.btn-secondary, .btn-primary {
  color:white !important;
}

.wrap {
  min-height:500px;
  padding:65px 0;
}

.widget {
  margin-bottom:40px;
  ul {
    margin:0;
    padding:0 0 0 20px;
    list-style-type: square;
  }
  h3 {
    font-size:1.3rem;
  }
}

.topbar {
  background:$gray-100;
  padding-top:5px;
  padding-bottom:5px;
  width:100%;
  color:$gray-500;
  font-size:0.8rem;
  a {
    color:$gray-500;
    text-decoration:none;
  }
}

.mini-nav {
  a {
    display:inline-block;
    padding-right:15px;
    padding-left:15px;
    border-right:1px solid $gray-500;
    &:last-of-type {
      padding-right:0;
      border-right:none;
    }
  }
}

.banner {
  padding-top:35px;
  padding-bottom:35px;
  border-bottom:4px solid theme-color("primary");
}

.nav-primary {
  padding-top:15px;
}

.nav-link {
  font-size:1.1rem;
  color:$gray-500;
  &:hover {
    background:theme-color("primary");
    color:white;
  }
}

.current-menu-item .nav-link {
  background:theme-color("primary");
  color:white;
}

.nav-trigger {
  display:none;
}


.main-footer {
  background:theme-color("primary");
  color:white;
  text-align:center;
  padding-top:100px;
  padding-bottom:70px;
  width:100%;
  img {
    transition:all 0.5s ease;
  }
  img:hover {
    opacity:0.8;
  }
  a {
    color:white;
  }

  .widget {
    margin:0;
    ul {
      list-style-type: none;
      padding:0;
      margin:50px 0 0;
    }
    li {
      display:inline-block;
      padding:0 10px;
      a {
        color:#b6d5f2;
        &:hover {
          color:white;
        }
      }
    }
    .current-menu-item {
      a {
        color:white;
        text-decoration: underline;
      }
    }
  }

}

.colophon {
  opacity:0.7;
  font-size:12px;
  margin-top:50px;
  color:#deeefc;
  a {
    color:#deeefc;
    text-decoration: none;
  }
}

.home-video {
  .fve-video-wrapper {
    margin:0;
    max-height:100vh;
  }
}


.btn {
  padding:14px 20px;
  font-size:1rem;
  text-transform: uppercase;
  font-weight:bold;
  cursor:pointer !important;
}

.vc_btn3.vc_btn3-color-primary, .vc_btn3.vc_btn3-color-primary.vc_btn3-style-flat {
  color:white;
  background:theme-color("primary") !important;
  font-weight:bold;
  font-family:$font-family-sans-serif;
  font-size:1rem;
  text-transform:uppercase;
  &:hover {
    color:white !important;
    background:lighten(theme-color("primary"), 10%) !important;
  }
}

.vc_btn3.vc_btn3-color-default, .vc_btn3.vc_btn3-color-primary.vc_btn3-style-flat {
  color:white;
  background:theme-color("secondary") !important;
  font-weight:bold;
  font-family:$font-family-sans-serif;
  font-size:1rem;
  text-transform:uppercase;
  &:hover {
    color:white !important;
    background:lighten(theme-color("secondary"), 10%) !important;
  }
}

.white-text, .white-text * {
  color:white !important
}

.page.contact {
  .main-footer {
    margin-top:-70px;
  }
}



.custom-callout-img {

  margin-bottom:-50px;
  margin-right:-30px;
}


.testimonials-main {
  min-height:400px !important;
}


.service-col {
  padding:5px;
}
.service-item.product-item {
  background:theme-color("primary");
  &:hover {
    background:theme-color("primary");
  }
}
.service-item {
  padding:50px 30px;
  width:100%;
  background: #4d5e92; /* Old browsers */
  background: -moz-linear-gradient(top,  #4d5e92 0%, #091f60 100%);
  background: -webkit-linear-gradient(top,  #4d5e92 0%,#091f60 100%);
  background: linear-gradient(to bottom,  #4d5e92 0%,#091f60 100%);
  text-align:center;
  position:relative;
  color:white;
  display:inline-block;
  transition:all 0.3s ease;
  img {
    width:auto;
    height:200px;
    display:inline-block;
    margin-bottom:20px;
  }
  h3 {
    color:white;
    font-weight:normal;
    margin-top:35px;
    margin-bottom:0;
    font-size:24px;
  }
  .service-overlay {
    opacity:0;
    width:calc(100% - 40px);
    height:calc(100% - 40px);
    background:theme-color("secondary");
    color:theme-color("primary");
    position:absolute;
    z-index:9999;
    top:20px;
    left:20px;
    right:20px;
    bottom:20px;
  }
  .service-overlay-content {
    padding:10px;
    h4 {
      font-size:20px;
      margin-bottom:5px;
      margin-top:0;
      font-weight:600;
    }
    .learn-more {
      color:white;
      font-weight:bold;
      margin-top:30px;
      text-transform: uppercase;
      font-size:1.1rem;
      &:hover {
        color:theme-color("primary");
      }
    }
  }
  &:hover {
    background: #091f60; /* Old browsers */
    background: -moz-linear-gradient(top,  #091f60 0%, #4d5e92 100%);
    background: -webkit-linear-gradient(top,  #091f60 0%, #4d5e92 100%);
    background: linear-gradient(to bottom,  #091f60 0%, #4d5e92 100%);
    text-decoration:none;
    .service-overlay {
      opacity:1;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.custom-callout-item {
  text-align:center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  h3 {
    height:195px;
    padding-top:40px;
  }
}



// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.99px) {
  .topbar {
    display:none;
  }
  .nav-primary {
    li {
      width:100%;
      display:block;
    }
    .nav {
      display:none;
    }
  }
  .nav-trigger {
    display:block !important;
    text-align:center;
    background:theme-color("secondary");
    color:white !important;
    font-weight:bold;
    &:hover {
      background:theme-color("secondary") !important;
      color:white !important;
    }
  }
  .services-container {
    width:96%;
    margin-left:auto;
    margin-right:auto;
  }
  .service-item img {
    height:auto;
    max-height:200px;

    width:60%;
  }

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.99px) {
  .topbar {
    text-align:center;
  }
  .nav-link {
    font-size:0.8rem;
    padding:.3rem .3rem;
  }
 .custom-left-column .vc_custom_1514685653971 {
    padding-left: 20px !important;
   padding-right:20px !important;
  }

  .vc_tta-has-pagination.testimonials-main {
    margin-left:15px !important;
    margin-right:15px !important;
    min-height:auto !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.99px) {
  .nav-link {
    font-size:0.8rem;
    padding:.5rem .3rem;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.99px) { }
